<template>
<span :class="className" class="paragraph medium">
    <NuxtImg :class="`${icon}`" :alt="icon || ''" aria-hidden focusable="false" />
    <span>{{ text }}</span>
    <slot />
</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        icon: String,
        primary: Boolean,
        outline: Boolean,
        text: String
    },

    computed: {
        className() {
            let className = 'tag'
            if (this.outline) {
                className += ' outline'
            } else {
                className += ' primary'
            }

            return className
        }
    }
})
</script>
