<template>
<NuxtLink
    :href="storefrontLink"
    class="external-storefront"
    :class="[platform!.vendor, getProductClass(pack!)]"
    target="_blank"
    external
>
    <div class="tile" :class="onSale">
        <div class="tile-contents">
            <PlatformIcon
                :platform="platform.icon"
                alt=""
                :classes="['storefront-icon']"
            />
            <p class="platform-name">{{ platform?.text }}</p>
            <p class="price">{{ priceString(platform) }}</p>
        </div>
        <div v-if="onSale == 'onsale'" class="sale">{{ $t('PACK_DETAIL.ON_SALE') }}</div>
    </div>
</NuxtLink>
</template>

<script lang="ts">
import type { Shopify } from '@jackboxgames/shopify'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import PlatformIcon from 'public/images/icons/platforms/PlatformIcon.vue'
import {
    getCurrencySymbol,
    getStorefrontLink,
    getProductGTMClass,
    localizedPriceString
} from '$services/storefronts/storefronts'
import type { JBGWeb } from '$types/JBGWeb'

export default defineComponent({
    components: { PlatformIcon },
    props: {
        platform: Object as PropType<JBGWeb.PlatformOption>,
        product: Object as PropType<Shopify.Product>,
        pack: String,
        fellBackToEn: {
            type: Boolean,
            required: false,
            default: false
        },
        isBundle: {
            type: Boolean,
            required: false,
            default: false
        },
        isLocalized: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        price() {
            if (!this.platform || !this.platform.currencyCode) return ''
            return `${getCurrencySymbol(this.platform.currencyCode)}${this.platform?.price}`
        },
        onSale() {
            if (!this.platform || !this.product) return false
            const retailPrice = Number(this.product.compareAtPriceRange.maxVariantPrice.amount)
            return (Number(this.platform?.price) < retailPrice) ? 'onsale' : ''
        },
        storefrontLink() {
            if (!this.pack || !this.platform?.vendor) return ''
            const { locale } = useI18n()
            // eslint-disable-next-line max-len
            return getStorefrontLink(locale.value, this.pack, this.platform?.vendor, this.fellBackToEn, this.isBundle, this.isLocalized).toString()
        }
    },

    methods: {
        getProductClass(packSlug: string) {
            return getProductGTMClass(packSlug)
        },
        priceString(option: JBGWeb.PlatformOption): string | undefined {
            const p = option.price
            if (!p) {
                return undefined
            }
            return localizedPriceString(p, option.currencyCode, this.$i18n.locale)
        }
    }
})
</script>

<style scoped lang="scss">
.tile {
    cursor: pointer;
    width: 140px;
    height: fit-content;
    padding: 8px;

    border-radius: 16px;
    border: 2px solid var(--stroke, #566376);
    background: linear-gradient(180deg, #43454F 0%, #2F2F3B 100%);
    transition: transform .2s;
    font-size: 14px;

    .tile-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8px;
    }

    &.onsale {
        border-color: var(--green-200);
    }

    &:hover {
        transform: scale(1.03, 1.03);
        border: 2px solid var(--neutral-50)
    }

    :deep(.storefront-icon) {
        height: 32px;
        color: var(--neutral-50);
    }

    .platform-name {
        margin: 0;
        color: var(--neutral-50);
    }

    .price {
        font-weight: 700;
        font-size: 16px;
        margin: 0;
        color: var(--neutral-50);
    }
    .sale {
        background-color: var(--green-200);
        color: var(--neutral-900);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 10px;
        z-index: 1;
        position: relative;
        bottom: -15px;
        padding: 3px;
        width: 70%;
        margin: 0 auto;
        text-align: center;
        border-radius: 10px;
    }
}

</style>
