<template>
<div class="video-tile">
    <ClientOnly>
        <NuxtImg
            role="button" tabindex="0"
            class="close"
            alt="close"
            src="/images/white-close.svg"
            v-on:click="closeClick"
            v-on:keydown.enter="closeClick"
        />
        <YouTube
            v-if="videoId"
            ref="youtube"
            :videoid="videoId"
            :loop="0"
            :autoplay="0"
            :height="videoHeight"
            :width="videoWidth"
            :controls="1"
        />
    </ClientOnly>
</div>
</template>

<script lang="ts">
import { YoutubeVue3 as YouTube } from 'youtube-vue3'

export default defineNuxtComponent({
    components: {
        YouTube
    },

    props: {
        videoId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            windowWidth: import.meta.client ? window?.innerWidth : 800,
            videoHeight: 207,
            videoWidth: 368
        }
    },

    mounted() {
        if (import.meta.client) {
            window.addEventListener('keyup', this.onEscPress)
            this.windowWidth = window.innerWidth
        }
    },

    unmounted() {
        window.addEventListener('keyup', this.onEscPress)
        // close the modal on unmount
        this.closeClick()
    },

    methods: {
        closeClick() {
            this.$emit('resolve', {})
        },
        onEscPress(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                this.$hideModal()
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.close {
    cursor: pointer;
    filter: drop-shadow(1px 1px 1px black);
    margin-top: 20px;
    position: absolute;
    float: right;
    z-index: 2;
    right: 45px;
}

.video-tile {
    :deep(iframe) {
        border-radius: 8px;
        display: block;
        margin: auto;

        width: auto;
        max-width: 100%;
        height: calc(100vh - 80px);
        aspect-ratio: 16/9;

        @include mq-medium-and-less {
            height: 50vh;
        }

        @include mq-xsmall {
            height: 30vh;
        }
    }
}
</style>
