<!-- eslint-disable vue/max-attributes-per-line -->
<template>
<div>
    <div v-if="status === 'denied'" class="birthday">
        <p class="large">{{ $t('AFTER_DARK.UNAUTHORIZED') }}</p>
        <ButtonElement
            class="outline button semibold"
            :aria="$t('FORM.BACK')"
            v-on:click="kickBackToHome" v-on:keyup.enter="kickBackToHome"
        >
            {{ $t('FORM.BACK') }}
        </ButtonElement>
    </div>
    <div v-else class="birthday">
        <p class="large semibold">{{ $t('FORM.VERIFY_YOUR_AGE') }}</p>
        <label for="dateOfBirth" class="label" aria-hidden />
        <div class="dropdowns">
            <v-select v-model="monthValue" :clearable="false" :searchable="false" :options="month" class="dropdown" />
            <v-select v-model="dayValue" :clearable="false" :searchable="false" :options="day" class="dropdown" />
            <v-select v-model="yearValue" :clearable="false" :searchable="false" :options="year" class="dropdown" />
        </div>
        <ButtonElement
            class="primary button semibold"
            :aria="$t('FORM.CONTINUE')"
            v-on:click="storeDate" v-on:keyup.enter="storeDate"
        >
            {{ $t('FORM.CONTINUE') }}
        </ButtonElement>
        <ButtonElement
            class="outline button semibold"
            :aria="$t('FORM.BACK')"
            v-on:click="kickBackToHome" v-on:keyup.enter="kickBackToHome"
        >
            {{ $t('FORM.BACK') }}
        </ButtonElement>
    </div>
</div>
</template>

<script lang="ts">
import { type PropType } from 'vue'
import ButtonElement from '$components/ButtonElement.vue'
import ratingsRegionMapJson from '$components/ratings/ratingsRegionMap.json'
import { currentCountry } from '$services/shopify'

const today = new Date()
export type BirthdayStatus = 'denied' | 'unknown' | 'approved'
export default defineNuxtComponent({
    components: {
        ButtonElement
    },
    props: {
        status: {
            type: String as PropType<BirthdayStatus>,
            required: true
        }
    },
    emits: ['status-update'],
    data() {
        return {
            rating: 'ESRB',
            monthValue: { label: today.toLocaleString('default', { month: 'long' }), value: today.getMonth() },
            dayValue: { label: today.getDay(), value: today.getDay() },
            yearValue: { label: today.getFullYear(), value: today.getFullYear() }
        }
    },
    computed: {
        ageRating() {
            // base the threshold on that region's rating
            let regionalThreshold = 17 // default to ESRB
            switch (this.rating) {
            case 'ACB':
                regionalThreshold = 15
                break
            case 'RARS':
                regionalThreshold = 18
                break
            case 'USK':
            case 'PEGI':
            case 'ClassInd':
                regionalThreshold = 16
                break
            default: regionalThreshold = 17
            }
            return regionalThreshold
        },
        month() {
            const m = []
            const d = new Date()
            d.setDate(1)

            for (let i = 0; i < 12; i++) {
                d.setMonth(i)
                m.push({ label: this.$d(d, { month: 'long' }), value: (i + 1) })
            }

            return m
        },
        day() {
            const d = []
            for (let i = 1; i <= 31; i++) {
                d.push({ label: i.toString(), value: i })
            }
            return d
        },
        year() {
            const y = []
            const currentYear = new Date().getFullYear()
            for (let i = currentYear; i > currentYear - 120; i--) {
                y.push({ label: i.toString(), value: i })
            }
            return y
        }
    },
    mounted() {
        void this.checkBirthday()
        Object.entries(ratingsRegionMapJson).forEach((rating) => {
            if (rating[1].includes(currentCountry.code)) {
                this.rating = rating[0]
            }
        })
    },
    methods: {
        checkBirthday() {
            const birthdayString = window.localStorage.getItem('birthday')
            if (birthdayString) {
                const birthday = Date.parse(birthdayString)
                const threshold = new Date()
                threshold.setFullYear(threshold.getFullYear() - this.ageRating)
                if (birthday < threshold.getTime()) {
                    this.$emit('status-update', 'approved')
                } else {
                    this.$emit('status-update', 'denied')
                }
            } else {
                this.$emit('status-update', 'unknown')
            }
        },
        kickBackToHome() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({ path: '/' }))
        },
        storeDate() {
            const birthday = new Date()
            birthday.setFullYear(this.yearValue.value)
            birthday.setMonth(this.monthValue.value)
            birthday.setDate(this.dayValue.value)
            window.localStorage.setItem('birthday', birthday.toISOString())
            if (!birthday) {
                this.$toast.add({
                    id: 'agegate',
                    type: 'warning',
                    text: this.$t('FORM.ERRORS.DOB_BLANK')
                })
                return
            }
            window.localStorage.setItem('birthday', birthday.toISOString())
            this.checkBirthday()
        }
    }
})
</script>

<style lang="scss" scoped>
@import "$styles/kit.scss";

.birthday {
    margin: 70px auto;
    display: flex;
    flex-direction: column;
    max-width: 452px;
    padding: 0px 12px;
    p {
        text-align: center;
        font-size: 16px;
        &.large {
            margin-top: 56px;
            font-size: 24px;
        }
    }
    .button {
        width: 100%;
        margin-bottom: 16px;
    }
    .dropdowns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        justify-content: center;
        width: 100%;
        margin-bottom: 15px;

        .dropdown {
            color: var(--neutral-300);
            font-family: Inter;
            font-size: 14px;
            max-height: 50px;
            min-width: fit-content;

            @include mq-medium-and-more {
                font-size: 14px;
            }

            :deep(.vs__selected) {
                // HACK: This prevents the prior selected value from jumping up
                // visually when the dropdown list is open.
                // TODO: Consider migrating to https://github.com/RemoteDevForce/tom-select
                flex-grow: 1;
                position: static;
                padding: 0;

                .selected {
                    flex-grow: 1;
                }
            }

            :deep(.vs__selected-options) {
                margin-left: 16px;

                @include mq-small-and-less {
                    margin-left: 4px;
                }
            }

            :deep(.vs__open-indicator) {
                height: auto;
                width: 16px;
                margin: 4px 4px 0px -10px;
            }

            :deep(.vs__dropdown-toggle) {
                max-height: 50px;
            }

            .selected {
                color: var(--neutral-300);
            }
        }
    }
}
</style>
