<template>
<div class="modal-wrapper">
    <ClientOnly>
        <NuxtImg
            role="button" tabindex="0"
            class="close"
            alt="promo.attributes.alternativeText"
            src="/images/white-close.svg"
            v-on:click="closeClick"
            v-on:keydown.enter="closeClick"
        />
        <Swiper
            class="modal-media-tile-container"
            :navigation="{ config }"
            :slide-to-clicked-slide="true"
            :grab-cursor="true"
            :speed="150"
            :init="false"
            :centered-slides="true"
            :modules="[SwiperNavigation, SwiperPagination]"
            :pagination="config"
            :slides-per-view="1"
            :space-between="16"
            v-on:slide-change="pauseVideo"
        >
            <SwiperSlide v-for="(promo, idx) in media" :key="idx">
                <div v-if="videoId && idx === 0" class="video-tile">
                    <YouTube
                        ref="youtube"
                        :videoid="videoId!"
                        :loop="0"
                        :autoplay="0"
                        :height="videoHeight"
                        :width="videoWidth"
                        :controls="1"
                    />
                </div>
                <NuxtImg
                    v-else
                    class="media-tile"
                    :alt="promo.attributes.alternativeText || ''"
                    :src="promo.attributes.url"
                    draggable="false"
                />
            </SwiperSlide>
        </Swiper>
    </ClientOnly>
</div>
</template>

<script lang="ts">
import type { SwiperContainer } from 'swiper/element'
import { YoutubeVue3 as YouTube } from 'youtube-vue3'

export default defineNuxtComponent({
    components: {
        YouTube
    },

    props: {
        media: {
            type: Object
        },
        videoId: {
            type: String,
            required: false
        },
        itemIndex: {
            type: Number,
            required: false
        }
    },

    emits: {
        resolve: (_: any) => true
    },

    data() {
        return {
            windowWidth: import.meta.client ? window?.innerWidth : 800,
            videoHeight: 207,
            videoWidth: 368,
            config: {
                1266: {
                    navigation: {
                        enabled: true
                    },
                    pagination: {
                        enabled: true
                    }
                }
            }
        }
    },

    mounted() {
        if (import.meta.client) {
            window.addEventListener('keyup', this.onEscPress)

            this.windowWidth = window.innerWidth
            // close modal when back or forward nav buttons are pressed
            window.onpopstate = () => {
                this.$hideModal()
            }
        }

        // add a slight delay to make sure the modal swiper container is rendered
        setTimeout(() => {
            const modalSwiperContainer: SwiperContainer = document.querySelector('.modal-media-tile-container')
            if (this.itemIndex) {
                modalSwiperContainer.swiper.slideTo(this.itemIndex, 1, false)
            }
        }, 200)
    },

    unmounted() {
        window.addEventListener('keyup', this.onEscPress)
        // close the modal on unmount
        this.closeClick()
    },

    methods: {
        closeClick() {
            this.$emit('resolve', {})
        },

        pauseVideo() {
            const modalSwiperContainer: SwiperContainer = document.querySelector('.modal-media-tile-container')
            // pause video if we go to slide 2 (index 1) and the video is playing
            console.log(modalSwiperContainer.swiper.activeIndex)
            if (modalSwiperContainer.swiper.activeIndex === 1) {
                this.$refs.youtube[0].player.pauseVideo()
            }
        },

        onEscPress(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                this.$hideModal()
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.modal-wrapper {
    .close {
        cursor: pointer;
        filter: drop-shadow(1px 1px 1px black);
        margin-top: 20px;
        position: absolute;
        float: right;
        z-index: 2;
        right: 45px;
    }

    .modal-media-tile-container {
        .media-tile {
            display: block;
            margin: auto;
            width: 100%;
            max-width: 1440px;
            max-height: 100%;

            @include mq-small-and-less {
                width: 100%;
            }
        }

        .video-tile {
            display: block;
            width: 100% !important;
            margin: auto;
            max-width: 1440px;
            max-height: 100%;

            :deep(iframe) {
                width: 100%;
                aspect-ratio: 16/9;
                height: auto;
            }
        }
    }
}

:deep() {
    @import '$styles/swiper';
}
</style>
